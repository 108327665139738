import React from "react";

import EditBox from './admin-editbox';

import '../styles/components/admin-panel.css';

const AdminPanel = () => {
  return (
    <>
      <div className='admin-panel'>
        <h2>Admin Panel</h2>
        <p>Select which part of the site you'd like to edit:</p>
        <div className='edit-box-wrapper'>
          <div className='edit-box--button-wrapper'>
            <button className='editbox-select'>Blog</button>
            <button className='editbox-select'>Publications</button>
            <button className='editbox-select'>Gallery</button>
          </div>
          <EditBox {...{ type:'blog' }}/>
          <EditBox {...{ type:'publication' }}/>
          <EditBox {...{ type:'artwork' }}/>
        </div>
      </div>
    </>
  )
}

export default AdminPanel