import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/layout"
import BackgroundImage from "../components/utilities/background-image"
import SEO from "../components/utilities/seo"

import AdminPanel from '../components/admin-panel'

import "../styles/pages/admin.css";

const prepImageForUpload = (dropEvent) => {
  let dt = dropEvent.dataTransfer;
  let files = dt.files;
  // upload 'files'
}

const AdminPage = () => {
  const data = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "artwork/Soar.jpeg" }) {
        ...galleryImage
      }
    }
  `);

  const submitPassword = () => true;
  
  return <Layout>
    <div className='site-background'>
      <BackgroundImage src={ data.background } />
      <div className='page-wrapper--limited'>
        <SEO title=""/>
        <h1 className='main-title'>Admin Area</h1>
        <p>This is the Admin area. If you're not an admin, then please
          &nbsp;
          <Link to="/">return to the main area</Link>
          &nbsp;of the site.
        </p>
        <div className='login-wrapper'>
          <input type='text' id='password' placeholder='enter your admin password'></input>
          <button id='login' onClick={submitPassword}>Login</button>
        </div>
        <AdminPanel></AdminPanel>
      </div>
    </div>
  </Layout>
}

export default AdminPage;
