import React from "react";

class ExpandableInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { height: '70px', width: '268px' };
    this.myInput = React.createRef();

    this.expandIfNeeded = this.expandIfNeeded.bind(this);
  }
  // expands the height of the textarea if the contents are larger than it can hold
  expandIfNeeded() {
    if (this.myInput.current.clientHeight < this.myInput.current.scrollHeight) {
      this.setState({
        height: this.myInput.current.scrollHeight + 2,
        width: this.myInput.current.clientWidth + 2,
      });
      // this.myInput.current.clientHeight = this.myInput.current.scrollHeight;
    } else if (this.myInput.current.scrollHeight > 200) {
      this.setState({ width: '100%' });
    } else {
      this.setState({ width: this.myInput.current.clientWidth + 2 });
    }
  }

  render() {
    return <div style={{width: this.state.width, height: this.state.height}}>
      <textarea
        style={{width: this.state.width, height: this.state.height}}
        ref={this.myInput}
        className='body input'
        type='text'
        placeholder={this.props.placeholder}
        onInput={this.expandIfNeeded}
        onClick={this.expandIfNeeded}
      >
      </textarea>
      {/* <button className='button-float' onClick={this.forceResize}>Resize</button> */}
    </div>;
  }
}

export default ExpandableInput;
