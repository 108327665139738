import React from "react"

import ExpandingInput from './utilities/expanding-input'

import '../styles/components/editbox.css'

const EditBox = ({ type }) => {
  // TODO: Make this a class component as it should really have its own state
  const placeholders = {
    title: 'Add a title',
    date: "Enter today's date",
    image: 'TODO: Add drag-and-drop',
    body: 'Enter the blog post text',
    medium: 'Enter the artwork medium',
    status: 'For Sale or Not Available',
    author: 'Enter the authors',
    publisher: 'Enter the publisher',
    description: "Add a description",
  }

  // format the date
  const date = new Date();
  const dateTimeFormat = new Intl.DateTimeFormat('en', { year: 'numeric', month: 'long', day: '2-digit', weekday: 'long' }); 
  const [{ value: weekday }, { value: month }, { value: day }, { value: year }] = dateTimeFormat.formatToParts(date);
  let suffix;
  if (day.slice(-1) === '1') {
    suffix='st'
  } else if (day.slice(-1) === '2') {
    suffix='nd'
  } else if (day.slice(-1) === '3') {
    suffix='rd'
  } else {
    suffix='th'
  }
  // set the default values
  const defaultValues = {
    date: `${weekday} ${day}${suffix} ${month} ${year}`,
  }
  // define the required fields for the edit box
  const fields = ['title'];
  const builtHTML = [];
  switch (type) {
    case 'blog':
      fields.push(...['date', 'image', 'body']);
      break;
    case 'artwork':
      fields.push(...['image', 'medium', 'status']);
      break;
    case 'publication':
      fields.push(...['author', 'publisher', 'description']);
      break;
    default:
      break;
  }
  fields.forEach((field) => {
    // Use a custom element for body as it may have more content.
    if (field === 'body') {
      builtHTML.push(
        <div className='field flex' key='body'>
          <label htmlFor='body' className='label'>Body:
          </label>
            <ExpandingInput name='body' placeholder={placeholders.body}/>
        </div>
      );
    } else {
      builtHTML.push(
        <div className='field flex' key={field}>
          <label htmlFor={field} className='label'>{field}:
          </label>
          <input
            className={field + ' input'}
            name={field}
            type='text'
            placeholder={placeholders[field]}
            defaultValue={defaultValues[field]}
          />
        </div>
      );
    }
  });

  return <form className='editbox' method="POST" name={`${type}-form`} data-netlify="true">
    {builtHTML}
    <button className='submit' type='submit'>Submit</button>
  </form>
}

export default EditBox;
